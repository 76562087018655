import styled from 'styled-components';

import { Link } from '@bscom/components/Links';
import { Spacing } from '@bscom/components/Spacing';
import { desktops } from '@bscom/styling/breakpoints';
import { Markdown } from '@bscom/components/Markdown';
import { MAX_PAGE_WIDTH } from '@bscom/constants/widths';
import { Background } from '@bscom/components/Background';
import { ButtonsFromType } from '@bscom/components/Buttons';
import { WHITE, GREY_REGULAR } from '@bscom/constants/colors';

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  max-width: 740px;
  flex-direction: column;

  @media (${desktops}) {
    width: calc(100% / 2 - 20px);
    margin: 0 10px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  display: flex;
  padding: 40px 25px;
  flex: 1 0 auto;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  background: ${WHITE};

  @media (${desktops}) {
    padding: 70px 60px;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const MainImage = styled.img`
  width: 100%;
  max-width: 100%;
`;

const IconImageWrapper = styled.div`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 20px;

  img {
    max-width: 100%;
    width: 100%;
  }

  @media (${desktops}) {
    margin-bottom: 24px;
  }
`;

const Headline = styled.h2`
  font-size: 21px;
  line-height: 26px;
  padding: 0 0 20px;

  @media (${desktops}) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const Splitter = styled.hr`
  width: 50px;
  height: 2px;
  margin-left: 0;
  margin-top: 0;
  border-width: 0;
  margin-bottom: 28px;
  background-color: ${GREY_REGULAR};
`;

const MainText = styled.div`
  margin-bottom: 44px;
`;

const LinkFromMarkdown = styled(Markdown)`
  margin-bottom: 56px;
`;

const StyledParagraph = styled.p`
  margin: 0 0 16px 0;
`;

const DescriptionLink = styled(Link)`
  text-decoration: none;

  &::before {
    content: url("data:image/svg+xml;utf8,<svg width='14' height='19' viewBox='0 0 14 19' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M14 7.75323L12.4052 6.10214L8.23942 10.4149L8.23942 -2.51803e-07L5.95981 -3.51448e-07L5.95981 10.6216L1.59481 6.10214L-3.38905e-07 7.75323L6.99977 15L14 7.75323Z' fill='%231A66FF'/><rect y='17' width='14' height='2' fill='%231A66FF'/></svg>");
    margin-right: 15px;
    vertical-align: -6px;
  }
`;

const descriptionRenderers = {
  p: ({ children }) => <StyledParagraph>{children}</StyledParagraph>,
  a: ({ href, children }) => (
    <DescriptionLink href={href}>{children}</DescriptionLink>
  ),
};

const MainImageContent = ({ icon, header, text, link, image, buttons }) => (
  <Container>
    {image && (
      <MainImage
        src={image.url}
        alt={image.title}
        title={image.description}
        loading="lazy"
      />
    )}
    <Content {...{ icon }}>
      {icon && (
        <IconImageWrapper>
          <img
            src={icon.url}
            alt={icon.title}
            title={icon.description}
            loading="lazy"
          />
        </IconImageWrapper>
      )}
      <Headline>{header}</Headline>
      <Splitter />
      <MainText>
        <Markdown customRenderers={descriptionRenderers}>{text}</Markdown>
      </MainText>
      {link && <LinkFromMarkdown>{link}</LinkFromMarkdown>}
      {buttons && (
        <ButtonsFromType
          buttons={buttons.map(({ link, ...button }) => ({
            ...button,
            href: link,
          }))}
        />
      )}
    </Content>
  </Container>
);

const PageTwoColumnSquareContent = ({
  background,
  contentCollection,
  spacingCollection,
}) => {
  return (
    <Background {...background}>
      <Spacing
        spaces={spacingCollection.items}
        withXPadding
        contentWidth={MAX_PAGE_WIDTH}
      >
        <ContainerWrapper>
          {contentCollection.items.map((content, index) => (
            <MainImageContent key={index} {...content} />
          ))}
        </ContainerWrapper>
      </Spacing>
    </Background>
  );
};

export default PageTwoColumnSquareContent;
